interface ICartReq {
	action: 'add' | 'remove' | 'mod',
	modValue?: -1 | 1,
	dataset: DOMStringMap,
}

interface Config {
	addCartBtn: boolean,
}

export function initCartScripts(parentEl: HTMLElement, config?: Config) {
	config = Object.assign({ addCartBtn: true }, config || {})

	if (config.addCartBtn) {
		const cartBtn = parentEl.querySelector<HTMLElement>('button[data-action="add_cart"]')

		if (!cartBtn) return console.error('Element should have button[data-action="add_cart"]. Element: \n', parentEl)

		cartBtn.addEventListener('click', () => addInCart(parentEl))
	}

	const incrBtn = parentEl.querySelector('button[data-action="amount_incr"]')
	const decrBtn = parentEl.querySelector('button[data-action="amount_decr"]')

	if (!incrBtn || !decrBtn) return console.error('Element should have button[data-action="amount_incr"] and button[data-action="amount_decr"]. Element:\n', parentEl)

	incrBtn.addEventListener('click', () => changeAmount(parentEl, 1))
	decrBtn.addEventListener('click', () => changeAmount(parentEl, -1))
}

async function changeAmount(parentItem: HTMLElement, mod: -1 | 1): Promise<void> {
	const amountEl = parentItem.querySelector<HTMLElement>('*[data-info="amount"]')
	if (!amountEl) return console.error('Element should have amount-value container with data-info="amount". Element: \n', parentItem)

	const newNum = Number(amountEl.innerText) + mod

	if (newNum < 1) return removeFromCart(parentItem)

	amountEl.innerText = String(newNum)

	const reqBody: ICartReq = {
		action: 'mod',
		modValue: mod,
		dataset: parentItem.dataset,
	}

	const res = await fetch(CONSTANT_VARS.ajaxPaths.cart, {
		method: 'POST',
		body: JSON.stringify(reqBody),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	if (!res.ok) return console.error('Error while changing amount in cart. Element: \n', parentItem, 'Response: \n', res)
}

async function removeFromCart(parentEl: HTMLElement): Promise<void> {
	const reqBody: ICartReq = {
		action: 'remove',
		dataset: parentEl.dataset,
	}

	const res = await fetch(CONSTANT_VARS.ajaxPaths.cart, {
		method: 'POST',
		body: JSON.stringify(reqBody),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	if (!res.ok) return console.error('Error while removing from cart. Element: \n', parentEl, 'Response: \n', res)
}

async function addInCart(parentEl: HTMLElement): Promise<void> {
	const reqBody: ICartReq = {
		action: 'add',
		dataset: parentEl.dataset,
	}

	const res = await fetch(CONSTANT_VARS.ajaxPaths.cart, {
		method: 'POST',
		body: JSON.stringify(reqBody),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	if (!res.ok) return console.error('Error while adding in cart. Card element: \n', parentEl, 'Response: \n', res)

	parentEl.classList.add('card_in-cart')
}